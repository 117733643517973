<template>
  <v-container class="app">
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-card>
            <span>Report for {{ today }} </span>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <span>Surveys</span>
          </v-card>
        </v-col>
      </v-row>
      <v-card>
        <RecordList :fields="survey_header" :records="surveys" v-on="handler">
        </RecordList>
        <!-- <v-row v-for="survey in surveys" :key="survey.survey_ts" cols="12">
          {{ survey }}
        </v-row> -->
      </v-card>
      <v-row>
        <v-col cols="12">
          <v-card>
            <span>Downed Birds</span>
          </v-card>
        </v-col>
      </v-row>
      <v-card>
        <RecordList :fields="downed_bird_header" :records="downed_birds">
        </RecordList>
        <!-- <v-row
          v-for="bird in downed_birds"
          :key="bird.observation_ts"
          cols="12"
        >
          {{ bird }}
        </v-row> -->
      </v-card>
      <v-row>
        <v-col cols="12">
          <v-card>
            <span>Predators</span>
          </v-card>
        </v-col>
      </v-row>
      <v-card>
        <RecordList :fields="predator_header" :records="predators">
        </RecordList>
      </v-card>
      <!-- <v-row>
        <v-col cols="12">
          <v-card>
            <span>Turtles</span>
          </v-card>
        </v-col>
      </v-row> -->
      <!-- <v-card>
        <RecordList :fields="turtle_header" :records="turtles">
        </RecordList></v-card>-->
    </v-col>
  </v-container>
</template>
<script>
import support from "../plugins/support";
import RecordList from "@/components/RecordsList.vue";

export default {
  name: "Summary-Form",
  components: { RecordList },
  data() {
    return {
      today: new Date(),
      handler: {
        record_selected: this.handleRecordSelected,
      },
    };
  },
  methods: {
    handleRecordSelected(recordInfo) {
      let i = support.findRecordPosition(support.SURVEYEFFORT, recordInfo.item);
      if (i > -1) {
        support.setListPosition(support.SURVEYEFFORT, i);
      }
    },
  },
  async mounted() {
    support.populateViews();
  },
  computed: {
    tablet() {
      return this.$store.getters.tabletCode;
    },
    survey_header() {
      return [
        { label: "tablet", key: "survey_tablet" },
        { label: "timestamp", key: "survey_ts" },
        { label: "checkpoints", key: "checkpoint_count" },
        //{ label: "observer", key: "observer_code" },
      ];
    },
    downed_bird_header() {
      return [
        { label: "survey_tablet", key: "survey_tablet" },
        { label: "timestamp", key: "observation_ts" },
        { label: "species", key: "field_species_code" },
        //{ label: "observer", key: "observer_code" },
      ];
    },
    predator_header() {
      return [
        { label: "survey_tablet", key: "survey_tablet" },
        { label: "timestamp", key: "observation_ts" },
        { label: "species", key: "predator_code" },
        //{ label: "observer", key: "observer_code" },
      ];
    },
    turtle_header() {
      return [
        { label: "survey_tablet", key: "survey_tablet" },
        { label: "timestamp", key: "observation_ts" },
        { label: "species", key: "turtle_code" },
        //{ label: "observer", key: "observer_code" },
      ];
    },
    surveys() {
      //tablet value changes even when on same tablet so ignore this filter
      //   return this.$store.state.views["recent_survey_effort"].filter(function (
      //     el
      //   ) {
      //     return el["survey_tablet"] === tablet;
      //   });
      return this.$store.state.views["recent_survey_effort"];
    },
    turtles() {
      return this.$store.state.views["recent_survey_turtle"];
    },
    predators() {
      return this.$store.state.views["recent_survey_predator"];
    },
    downed_birds() {
      return this.$store.state.views["recent_survey_downed_bird"];
    },
  },
};
</script>
